import _ from 'lodash'
import moment from 'moment'
import React, {
  useEffect,
} from 'react'
import { useBooking } from '../../hooks/use-booking'
import CheckoutCompleteView from './checkout-complete-view'

const CheckoutCompleteController = () => {
  const defaultFrom = {
    year: moment().year(),
    month: moment().month() + 1,
    day: moment().date(),
  }
  const pastTommorow = moment().add(2, 'd')
  const defaultTo = {
    year: moment(pastTommorow).year(),
    month: moment(pastTommorow).month() + 1,
    day: moment(pastTommorow).date(),
  }

  const {
    setTotalRooms,
    setTotalChildren,
    setTotalAdults,
    setBookFrom,
    setBookTo,
    setError,
  } = useBooking()

  useEffect(() => {
    sessionStorage.removeItem('paramsBooking')
    setTotalRooms([])
    setTotalChildren(0)
    setTotalAdults(2)
    setBookFrom(defaultFrom)
    setBookTo(defaultTo)
    setError(null)
  }, [])

  return (
    <CheckoutCompleteView />
  )
}

export default CheckoutCompleteController
