// extracted by mini-css-extract-plugin
export var active = "checkout-complete-styles-module--active--da1f6";
export var arrow = "checkout-complete-styles-module--arrow--d6894";
export var arrowDisabled = "checkout-complete-styles-module--arrow--disabled--0449f";
export var arrowLeft = "checkout-complete-styles-module--arrow--left--e8711";
export var arrowRight = "checkout-complete-styles-module--arrow--right--96861";
export var blink = "checkout-complete-styles-module--blink--27c48";
export var bookingBtn = "checkout-complete-styles-module--booking-btn--0a9a0";
export var bookingPageHeader = "checkout-complete-styles-module--booking-page-header--93bbf";
export var checkout = "checkout-complete-styles-module--checkout--70490";
export var checkoutComplete__box = "checkout-complete-styles-module--checkoutComplete__box--b97c0";
export var checkoutComplete__container = "checkout-complete-styles-module--checkoutComplete__container--cb1f6";
export var checkoutComplete__content = "checkout-complete-styles-module--checkoutComplete__content--b9290";
export var checkoutComplete__content__group__values = "checkout-complete-styles-module--checkoutComplete__content__group__values--a91bd";
export var checkoutComplete__content__values = "checkout-complete-styles-module--checkoutComplete__content__values--2577e";
export var checkoutComplete__content__valuesBox = "checkout-complete-styles-module--checkoutComplete__content__valuesBox--897c9";
export var checkoutComplete__footer = "checkout-complete-styles-module--checkoutComplete__footer--fbf31";
export var checkoutComplete__head = "checkout-complete-styles-module--checkoutComplete__head--0f42e";
export var commentsSlider = "checkout-complete-styles-module--commentsSlider--ddbe0";
export var dot = "checkout-complete-styles-module--dot--ed370";
export var dots = "checkout-complete-styles-module--dots--9f173";
export var equallyBound = "checkout-complete-styles-module--equallyBound--97f19";
export var fader = "checkout-complete-styles-module--fader--331f5";
export var fader__slide = "checkout-complete-styles-module--fader__slide--8a82c";
export var featuredSlider = "checkout-complete-styles-module--featuredSlider--63bf2";
export var globalWrapper = "checkout-complete-styles-module--global-wrapper--5d361";
export var headerIcons = "checkout-complete-styles-module--header-icons--3a1b5";
export var homepage = "checkout-complete-styles-module--homepage--cfd0e";
export var homepageReviews = "checkout-complete-styles-module--homepage-reviews--54ad6";
export var isOpenMenu = "checkout-complete-styles-module--is-open-menu--c0a1a";
export var keenSlider = "checkout-complete-styles-module--keen-slider--026f2";
export var lineMove = "checkout-complete-styles-module--lineMove--0c2f8";
export var logo = "checkout-complete-styles-module--logo--f4f1a";
export var navigationWrapper = "checkout-complete-styles-module--navigation-wrapper--8affd";
export var scrollNav = "checkout-complete-styles-module--scrollNav--ef6fb";
export var scrollNavWrap = "checkout-complete-styles-module--scrollNavWrap--c9757";
export var slickSlide = "checkout-complete-styles-module--slick-slide--53d05";
export var startTxtArea = "checkout-complete-styles-module--startTxtArea--7907a";