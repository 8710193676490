import _ from 'lodash'
import React, { useEffect, useState } from 'react'
import { Link } from 'gatsby'
import { useTranslation } from 'react-i18next'
import { getRoute } from '../../helpers'
import { Button, Spinner } from '../../components'
import * as styles from './checkout-complete-styles.module.scss'
import Seo from '../../components/Seo'


const CheckoutCompleteView = ({

  loading,
}) => {
  const { t, i18n } = useTranslation()

  const getTitle = (title) => {
    const slugTitle = title.replace(/ /g, '-');

    const titles = {
        'Garden-River-Suite-ja': 'ガーデンリバースイート',
        'Garden-River-Suite-zh-hant': '花园河景套房',
        'Executive-River-Suite-ja': 'エグゼクティブ・リバー・スイート',
        'Executive-River-Suite-zh-hant': ' 行政河景套房',
        'Panorama-River-Suite-ja': 'パノラマリバースイート',
        'Panorama-River-Suite-zh-hant': '全景河景套房',
    };

    let titleStr = title;

    console.log(titles, `${slugTitle}-${i18n.language.toLowerCase()}`)

    if(titles.hasOwnProperty(`${slugTitle}-${i18n.language.toLowerCase()}`)){
        titleStr = titles[`${slugTitle}-${i18n.language.toLowerCase()}`];
    }

    return titleStr;
  }

  const [bookingFormDetails, setBookingFormDetails] = useState(null)
  const [bookingDetails, setBookingDetails] = useState(null)
  const [bookRooms, setBookRooms] = useState([])

  useEffect(() => {

    const bookingFormDetailsStorage = sessionStorage.getItem('bookingFormDetails') ? JSON.parse(sessionStorage.getItem('bookingFormDetails')) : null
    const bookingDetailsStorage = sessionStorage.getItem('bookingDetails') ? JSON.parse(sessionStorage.getItem('bookingDetails')) : null
    const bookRoomsStorage = bookingFormDetailsStorage.rooms ? JSON.parse(bookingFormDetailsStorage.rooms) : []

    console.log("RDETAILS", bookingFormDetailsStorage, bookingDetailsStorage, bookRoomsStorage)

    setBookingFormDetails(bookingFormDetailsStorage)
    setBookingDetails(bookingDetailsStorage)
    setBookRooms(bookRoomsStorage)

    return () => {
        // sessionStorage.removeItem('bookingFormDetails')
        // sessionStorage.removeItem('bookingDetails')
    }
  }, [])

  if (loading) {
    return <Spinner loading={true} />
  }

  return (
    <div className={styles.checkoutComplete__container}>
          <Seo title="Kamo - Checkout Complete" />
      <div className={styles.checkoutComplete__box}>

        <div className={styles.checkoutComplete__head}>
          <h1>{t('checkoutComplete.title')}</h1>
          <p>{t('checkoutComplete.description')}</p>
        </div>
        <div className={styles.checkoutComplete__content}>
          {bookingFormDetails && bookingDetails && <>
            <h2>{t('checkoutComplete.bookingNumber')}: {bookingDetails.transactionID } </h2>
            <div className={styles.checkoutComplete__content__valuesBox}>
                <h4>{t('checkoutComplete.bookingDetails')}</h4>
                {
                bookRooms && _.map(bookRooms, (detail) => (
                    <div key={_.get(bookingDetails, 'transactionID')} className={styles.checkoutComplete__content__group__values}>
                        <div className={styles.checkoutComplete__content__values}>
                            <h5>{t('checkoutComplete.rooms')}</h5>
                            <p>{getTitle(_.get(detail, 'roomTypeName'))}</p>

                        </div>
                        <div className={styles.checkoutComplete__content__values}>
                            <h5></h5>
                            <p>{t('checkoutComplete.price', { price: _.get(detail, 'roomRate') })}</p>
                        </div>
                    </div>
                ))
                }
                <div className={styles.checkoutComplete__content__values}>
                    <h5>{t('checkoutComplete.checkIn')}</h5>
                    <p>{_.get(bookingFormDetails, 'startDate')}</p>
                </div>
                <div className={styles.checkoutComplete__content__values}>
                    <h5>{t('checkoutComplete.checkOut')}</h5>
                    <p> {_.get(bookingFormDetails, 'endDate')}</p>
                </div>
            </div>
            <div className={styles.checkoutComplete__content__valuesBox}>
                <h4>{t('checkoutComplete.contact.title')}</h4>
                <div className={styles.checkoutComplete__content__values}>
                <h5>{t('checkoutComplete.contact.name')}</h5>
                <p>{_.get(bookingFormDetails, 'guestFirstName')}{' '}{_.get(bookingFormDetails, 'guestLastName')}</p>
                </div>
                <div className={styles.checkoutComplete__content__values}>
                <h5>{t('checkoutComplete.contact.email')}</h5>
                <p>{_.get(bookingFormDetails, 'guestEmail')}</p>
                </div>
            </div>
            <div className={styles.checkoutComplete__content__valuesBox}>
                <div className={styles.checkoutComplete__content__values}>
                <h5>{t('checkoutComplete.total')}</h5>
                <span>{t('checkoutComplete.price', { price: _.get(bookingFormDetails, 'amount') })}</span>
                </div>
            </div>
          </>}
        </div>
        <div className={styles.checkoutComplete__footer}>
          <Link to={getRoute('/booking/', i18n.language)}>
            <Button >{t('checkoutComplete.buttons.book')}</Button>
          </Link>
          <Link to={getRoute('/', i18n.language)}>
            <span>{t('checkoutComplete.buttons.back')}</span>
          </Link>

        </div>
      </div>
    </div>

  )
}

export default CheckoutCompleteView
